import React from 'react';
import './Pvc.css'; // If you want to style this page

function CPVCpipes() {
  return (
    <div className="pvc-pipes-page">
      {/* Coming Soon Banner */}
     

      <h1>CPVC Pipes & Fittings</h1>
      <div className="content-container">
        <div className="text-content">
          <h2>Coming Soon...</h2>
          <p>Stay tuned for more details on CPVC Pipes & Fittings.</p>
        </div>

        {/* Placeholder for images */}
        <div className="image-container">
          <p>Images and more content will be available soon.</p>
        </div>
      </div>
    </div>
  );
}

export default CPVCpipes;
