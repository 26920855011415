import React from 'react';
import Ap from './assets/SWR-pipes.jpg'
import Ap1 from './assets/valve.webp'
import ap from './assets/valve2.jpeg'
import pi1 from './assets/valve.webp'
import pi2 from './assets/presure.jpeg'
import pi3 from './assets/hot.jpeg'
import pi4 from './assets/valve2.jpeg'
import pi5 from './assets/pre2.jpeg'
import pi6 from './assets/p6.webp'
import pi7 from './assets/p7.webp'
import pi8 from './assets/p8.webp'
import pi9 from './assets/p9.webp'
import logo from './assets/logo (3).png'
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

function About() {
  return (
    <div>
      <section className="about_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="detail-box">
                <div className="heading_container">
                  <h2>About Us</h2>
                </div>
                <p>
                  At Urja Pipe Solutions, we are dedicated to providing high-quality agricultural pipes designed to meet the needs of modern farmers. With years of experience in the industry, our products are engineered for durability, efficiency, and reliability. Whether you're looking for pipes for irrigation, drainage, or water management, we have the right solutions for your farm.
                </p>
                <p>
                  Our team of experts is committed to helping you select the best products and providing the support you need to ensure your agricultural systems operate smoothly. We believe in sustainable farming practices, and our products are designed to optimize water use and contribute to more efficient farming operations.
                </p>
                <a href="">Read More</a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img_container">
                <div className="img-box b1">
                  <img src={Ap} alt="Agricultural Pipe 1" />
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>

     







    </div>
  );
}

export default About;
