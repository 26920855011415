import React from 'react';
import logo from './assets/logo (3).png';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <div>
      <section className="info_section layout_padding">
        <div className="container">
          <div className="info_contact">
            <div className="row">
              {/* GST Number Section */}
              <div className="col-md-4 col-12">
                <div className="info_item">
                  <a href="#">
                    <img src="images/location-white.png" alt="GSTIN" />
                    <span>GSTIN: 27AAMPC0305Q2ZN</span>
                  </a>
                </div>
              </div>

              {/* Address Section */}
              <div className="col-md-4 col-12">
                <div className="info_item">
                  <a href="#">
                    <img src="images/location-white.png" alt="Address" />
                    <span>Westend Industries, J-36 MIDC AREA, Jalgaon</span>
                  </a>
                </div>
              </div>

              {/* Contact Section */}
              <div className="col-md-4 col-12">
                <div className="info_item">
                  <a href="#">
                    <img src="images/telephone-white.png" alt="Call Us" />
                    <span>Call: +91 8290090062</span>
                  </a>
                </div>
              </div>
            </div>

            {/* Email Section */}
            <div className="row email-section">
              <div className="col-12 text-center">
                <p className="email-text">
                  <FontAwesomeIcon icon={faEnvelope} className="email-icon" />&nbsp;
                  Email:
                  <a href="mailto:sales@urjaapolyvins.com"> sales@urjaapolyvins.com</a> |
                  <a href="mailto:Samir.t@urjaapolyvins.com"> Samir.t@urjaapolyvins.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="container-fluid footer_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-9 mx-auto text-center">
              <p>
                &copy; 2024 All Rights Reserved By
                <a href="https://www.shreedigimarketing.in/"> SHREE DIGITAL MARKETING AGENCY </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
