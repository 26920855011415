// Dealer.js (React component)

import React from 'react';
import './Dealer.css'; // Ensure the CSS file is imported

const Dealer = () => {
  return (
    <div className="finolex-bg">
      <div className="finolex-container">
        <div className="finolex-content">
          <h1 className="finolex-title">Join Us in Our Success Legacy
          </h1>
          <p className="finolex-description" style={{textAlign:'justify'}}>
          Accept a culture that prioritizes growth, collaboration, and creative thinking by becoming a member of the Urjaa family. Experience the joy of working for a respectable organization that is well-known for its unwavering commitment to excellence.
          </p>
          <p className="finolex-description" style={{textAlign:'justify'}}>
          As we celebrate more than 20 years of consistent growth and achievement, let's work together to usher in a new age of innovation and expansion.

          </p>
          <div className="finolex-button-wrapper">
            <a href="inquiry" className="finolex-button">Become a Dealer</a>
            <a href="contact" className="finolex-button">Explore Careers</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dealer;
